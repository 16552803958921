import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';

function App() {
  const [theme, setTheme] = useState('light');

  return (
    <BrowserRouter>
      <Navigation setTheme={setTheme} />
      <Routes>
        <Route path="/" element={<Home theme={theme} />} />
        <Route path="/about" element={<About theme={theme} />} />
        <Route path="/projects" element={<Projects theme={theme} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import { Container } from 'react-bootstrap';

const Home = ({ theme }) => {
  const homeStyle = theme === 'dark'
    ? {
        background: 'linear-gradient(to right top, #101112, #0c141d, #071627, #041831, #05183b, #041d42, #03214a, #002651, #003056, #003a58, #004359, #084b58)',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '100px 15px', // Adjust padding for smaller screens
    }
    : {
        background: 'linear-gradient(to right top, #86c8f9, #6ad8f8, #65e5eb, #80efd4, #abf6bb, #c0f4b4, #d2f2b0, #e2f0af, #dbe9b8, #d5e1c0, #d1d9c8, #cfd0ce)',
        color: '#105D60',
        display: 'flex',
        alignItems: 'center',
        padding: '100px 15px', // Adjust padding for smaller screens
    };

  return (
    <section className="home" style={homeStyle}>
      <Container>
        <div className="d-flex flex-column align-items-center">
          <div className="wrap-text text-center">
            <span className="tagline" style={{fontFamily: 'Helvetica, serif'}}>Welcome to my Portfolio</span>
            <h2>Hi, I'm Fariduddin Jiddan, a fullstack web developer</h2>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Home;

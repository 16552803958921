import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const About = ({ theme }) => {
  const aboutStyle = theme === 'dark'
    ? {
        background: 'linear-gradient(to right top, #101112, #0c141d, #071627, #041831, #05183b, #041d42, #03214a, #002651, #003056, #003a58, #004359, #084b58)',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: '100px 15px', // Adjust padding for smaller screens
    }
    : {
        background: 'linear-gradient(to right top, #86c8f9, #6ad8f8, #65e5eb, #80efd4, #abf6bb, #c0f4b4, #d2f2b0, #e2f0af, #dbe9b8, #d5e1c0, #d1d9c8, #cfd0ce)',
        color: '#105D60',
        display: 'flex',
        alignItems: 'center',
        padding: '100px 15px', // Adjust padding for smaller screens
    };

  return (
    <section className='about' style={aboutStyle}>
      <Container>
        <Row className="align-items-center">
          {/* <Col md={4}>
            <Image src="path_to_your_profile_picture.jpg" roundedCircle fluid />
          </Col> */}
          <Col md={8}>
            <div className="about-text">
              <h2>About Me</h2>
              <p>
                Hi, I'm Fariduddin Jiddan, you can call me Jiddan, a fullstack web developer with a passion for creating innovative and efficient web applications. With experience in both front-end and back-end development, I enjoy tackling new challenges and continuously learning new technologies.
              </p>
              <p>
                When I'm not coding, I love spending time reading, listening some music, exploring new places. I'm always eager to connect with like-minded professionals and collaborate on exciting projects.
              </p>
              <p>
                <strong>Skills:</strong> Java, Python, JavaScript, Springboot, Node.js, Flask, Express.js, React, PostgreSQL, MySQL, HTML, CSS, BootstrapCSS, Git, Linux CLI, Docker, and many more.
              </p>
              <p>
                <strong>Contact:</strong> mail@jiddan.id
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

function NewsFetcherForm({ onSubmit }) {
  const [newsUrl, setNewsUrl] = useState('');
  const [newsSelector, setNewsSelector] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newsUrl, newsSelector);
  };

  return (
    <section className="news-fetcher-form" style={{ padding: '20px 15px' }}>
      <Container>
        <div className="d-flex flex-column align-items-center">
          </div>
          <form onSubmit={handleSubmit}>
            <table style={{ backgroundColor: 'transparent' }}>
              <tbody>
                <tr>
                  <td style={{ fontSize: '11pt', fontFamily: 'Trebuchet MS, serif', color: '#808080' }}>
                    URL to article*
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      id="newsUrl"
                      value={newsUrl}
                      onChange={(e) => setNewsUrl(e.target.value)}
                      style={{
                        height: '40px',
                        width: '100%',
                        borderRadius: '5px',
                        border: '1px solid #668cff',
                        fontSize: '14pt',
                        fontFamily: 'Calibri, serif',
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: '11pt', fontFamily: 'Trebuchet MS, serif', color: '#808080' }}>
                    CSS selector*
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      id="newsSelector"
                      value={newsSelector}
                      onChange={(e) => setNewsSelector(e.target.value)}
                      style={{
                        height: '40px',
                        width: '100%',
                        borderRadius: '5px',
                        border: '1px solid #668cff',
                        fontSize: '14pt',
                        fontFamily: 'Calibri, serif',
                      }}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      type="submit"
                      style={{
                        height: '40px',
                        width: '100%',
                        borderRadius: '12px',
                        backgroundColor: 'rgba(239, 240, 240, 0.85)',
                        fontSize: '14pt',
                        fontFamily: 'Calibri, serif',
                        color: '#365556',
                        borderColor: 'gray',
                      }}
                    >
                      Fetch Berita
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
      </Container>
    </section>
  );
}

export default NewsFetcherForm;

import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Navigation({ setTheme }) {
  const toggleTheme = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'light' ? 'dark' : 'light';
      console.log(`Switched to ${newTheme} mode`);
      return newTheme;
    });
  };

  return (
    <Navbar expand="lg" className="navbar" style={{ backgroundColor: 'rgba(239, 240, 240, 0.85)', transition: '0.3s ease-in-out' }}>
      <Container>
        <Navbar.Brand href="#home" style={{ color: '#365556', fontFamily: 'Georgia, sans-serif', fontWeight: 600, fontSize: '20px' }}>
          Jiddan
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} to="/" style={{ color: '#365556', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: '18px', padding: '0 25px', letterSpacing: '0.8px', opacity: 0.8 }}>
              <i className="ri-home-line ri-lg"></i> Home
            </Nav.Link>
            <Nav.Link as={NavLink} to="/about" style={{ color: '#365556', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: '18px', padding: '0 25px', letterSpacing: '0.8px', opacity: 0.8 }}>
              <i className="ri-user-line ri-lg"></i> About Me
            </Nav.Link>
            <Nav.Link as={NavLink} to="/projects" style={{ color: '#365556', fontFamily: 'Montserrat, sans-serif', fontWeight: 400, fontSize: '18px', padding: '0 25px', letterSpacing: '0.8px', opacity: 0.8 }}>
              <i className="ri-folder-line ri-lg"></i> Projects
            </Nav.Link>
          </Nav>
          <div className="theme-toggle" style={{ paddingLeft: '20px', cursor: 'pointer' }} onClick={toggleTheme}>
            <i className="ri-sun-line ri-lg" style={{ color: '#000' }}></i>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
